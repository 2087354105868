<template>
  <div class="user page">
    <Nav></Nav>
    <div class="wrap" style="padding-top:10px;min-height: 700px;">
      <a-layout>
        <a-layout-sider>
          <Menu></Menu>
        </a-layout-sider>
        <a-layout>
          <a-layout-header>
            <a-row type="flex" justify="center" align="middle" class="top">
              <a-col :span="6" @click="go('user/Blances')">
                <a-icon type="account-book" /> 余额：{{user.balance+user.balanceB}}
              </a-col>
              <a-col :span="6" @click="go('user/Bonuspoints')">
                <icon-font type="icon-bonuspoints" /> 积分：{{user.integral}}
              </a-col>
              <a-col :span="6" @click="go('user/Coupons')">
                <icon-font type="icon-coupons" /> 礼券：{{user.couponCount}}张
              </a-col>
              <a-col :span="6" @click="go('user/Mypost')">
                <a-icon type="solution" /> 运单{{user.waybillCount}}张
              </a-col>
            </a-row>
          </a-layout-header>
          <a-layout-content>
            <div>
              <Neworder :list="waybill"></Neworder>
            </div>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Neworder from "./user/components/neworder";
import Menu from "./user/components/menu";
import Foot from "../components/foot";
import Nav from "@/components/Nav.vue";
import { Icon } from "ant-design-vue";
const IconFont = Icon.createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_2747721_gimu9jlw02u.js",
});
export default {
  components: {
    Neworder,
    Menu,
    Nav,
    Foot,
    IconFont,
  },
  data() {
    return {
      user: {},
      waybill: [],
    };
  },
  computed: {},
  beforeCreate(){
   
  },
  created() {
    if (this.$route.name === "Home") this.main_swiper = true;
  },
  mounted() {
    this.user = this.storage.getItem("user");
    if(!this.user){
      this.getUserInfo();
    }

    this.api
        .getmywaybill()
        .then((res) => {
          this.waybill = res.reveive;
        })
        .catch((err) => {
          console.log(err);
        });
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "fetchDate",
  },
  methods: {
    fetchDate() {
      if (this.$route.name !== "Home") {
        this.main_swiper = false;
      } else {
        this.main_swiper = true;
      }
    },
    getUserInfo() {
      this.api
        .getuserDetail({})
        .then((res) => {
          this.user = res;
          this.storage.setItem("user", res);
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    go(e) {
      console.log(e);
      this.$router.push(e);
    },
  },
};
</script>


<style lang="less">
@import "~ant-design-vue/dist/antd.less";

.page {
  background: #f5f5f5;
}

.wrap {
  height: 100%;
  // padding-bottom: 20px;
}

.ant-layout {
  // height: 100%;
}

.ant-layout-sider,
.ant-layout-content,
.ant-layout-header,
.ant-layout-footer {
  background: #ffffff;
  padding: 10px;
}

.ant-layout-sider {
  margin-right: 10px;
  text-align: center;
}

.ant-layout-content {
  margin-top: 10px;
}

.ant-layout-header {
  height: auto;

  .top {
    .ant-col {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .anticon {
      font-size: 24px;
      margin-right: 10px;
    }
  }
}

.user {
  .user-name {
    font-size: 14px;
    margin-top: 10px;
  }

  .other-info {
    text-align: left;
    margin-left: 30px;

    span {
      color: @text-color-secondary;
    }
  }
}

.menu {
  text-align: left;
  padding-left: 30px;
  line-height: 28px;
  margin-top: 30px;
  border-top: 1px solid #e2e2e2;

  dl {
    padding-top: 10px;

    dt {
      font-size: 16px;
      line-height: 28px;
    }

    dd {
      color: @text-color-secondary;
    }
  }
}

div.content-wrap {
  padding: 15px;
}

.cop {
  flex-wrap: wrap;
  justify-content: space-between;

  .copItem {
    width: 480px;
    display: flex;
    justify-content: space-between;
    background: #fff3f3;
    align-items: center;
    position: relative;
    border-radius: 8px;
    margin-bottom: 10px;

    .l {
      flex: 1;
      border-right: 2px dotted #999;
      width: 60%;
      padding: 9px 0;
      padding-left: 9px;

      .money {
        font-size: 36px;
        color: #CF0022;
      }

      .title {
        padding: 5px 0;
      }

      .time {
        color: #909399;
      }
    }

    .r {
      text-align: center;
      width: 30%;
    }
  }
}


.bla-top {
  background-size: cover;
  padding: 15px;
}

.bla-head {
  display: flex;
  justify-content: space-between;

  .bla-txt {
    font-size: 36px;
    color: #CF0022;
    margin-top: 5px;
  }
}

.bla-foot {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  .bla-invest-total {
    .bla-txt {
      font-size: 36px;
      color: #CF0022;
      margin-top: 5px;
    }
  }

  .bla-consume-total {
    .bla-txt {
      font-size: 36px;
      color: #CF0022;
      margin-top: 5px;
    }
  }
}

.sign-coupon {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;

  .sign {
    background: #fff6d1;
  }

  .coupon {
    background: #fff3f3;
  }
  .item {
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    width: 48%;
    padding: 9px;
    color: #CF0022;

    .u-icon--right {
      flex-direction: row;
      align-items: center;
      font-size: 30px;
    }

    .info {
      font-weight: 400;
      font-size: 11px;
      margin-top: 5px;
    }
    .txt {
      font-size: 15px;
      font-weight: 600;
    }
  }
}
</style>