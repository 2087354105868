<template>
  <div>
    <a-page-header class="b-btm" title="最新快件动态" @back="() => null" />
    <div class="content-wrap">
      <a-row :gutter="[16, 16]" class="waybill-list">
        <a-col :span="12" v-for="(item, index) in list" :key="index">
          <a-card :title="`运单号：${item.number}`" :hoverable="true">
            <router-link  slot="extra" :to="`WaybillDetail?id=${item.number}`"
              >运单详情
              <a-icon type="right" />
            </router-link >
            <div class="waybill-info">
              <div class="post-city-user">
                <div class="city">
                  {{ item.senderProvinceName }}
                </div>
                <div class="user">
                  {{ item.senderName }}
                </div>
              </div>
              <div class="state">
                {{ item.waybillStateName }}
              </div>
              <div class="receive-city-user">
                <div class="city">
                  {{ item.receiverProvinceName }}
                </div>
                <div class="user">
                  {{ item.receiverName }}
                </div>
              </div>
            </div>
            <div class="state">
              {{ item.waybillStateName }}：{{ item.waybillStateName }}
            </div>
            <div class="time u-flex" style="border-top: 1px solid #e4e7ed">
              <div class="timeitem">
                {{ item.waybillStateName }}时间：{{ item.waybillStateTime }}
              </div>
              <div class="price">运费：{{ item.payAmount }}</div>
            </div>
          </a-card>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="less" scoped>
@import "~ant-design-vue/dist/antd.less";

.waybill-list {
  // padding: 15px;

  .ant-card-bordered {
    border-radius: 5px;
  }

  .state {
    padding-top: 10px;
    padding-bottom: 8px;
  }

  .time {
    padding-top: 5px;
    justify-content: space-between;

    .timeitem {
      color: #c8c9cc;
    }

    .price {
      color: #CF0022;
      font-size: 15px;
      font-weight: 600;
    }
  }
}

.waybill-info {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .city {
    font-size: 16px;
    font-weight: 600;
    line-height: 2;
  }

  .user {
    font-size: 14px;
    color: @text-color-secondary;
  }

  div {
    text-align: center;
  }
}
</style>